import { render, staticRenderFns } from "./PrivacyPolicy.vue?vue&type=template&id=668c4f34&functional=true&"
import script from "./PrivacyPolicy.vue?vue&type=script&setup=true&lang=ts&"
export * from "./PrivacyPolicy.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports