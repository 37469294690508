function initialState() {
  return {
    videoRecording: null,
    modalType: ''
  };
}

const getters = {
  videoRecording: (state) => state.videoRecording,
  modalType: (state) => state.modalType
};

const mutations = {
  SET_VIDEO_RECORDING(state, payload) {
    state.videoRecording = payload;
  },
  SET_MODAL_TYPE(state, payload) {
    state.modalType = payload;
  },
  SET_VIDEO_DURATION(state, payload) {
    state.videoRecording = { ...state.videoRecording, ...payload };
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  setVideoRecording({ commit }, file) {
    commit('SET_VIDEO_RECORDING', file);
  },
  setVideoDuration({ commit }, duration) {
    commit('SET_VIDEO_DURATION', duration);
  },
  setModalType({ commit }, type) {
    commit('SET_MODAL_TYPE', type);
  },
  resetModal({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
